import { LocalScheme } from '#auth/runtime';

export default class CustomScheme extends LocalScheme {
  constructor($auth, options, ...defaults) {
    const tenantSlug = useRoute().params.tenantSlug || '';
    options.token.prefix = `${tenantSlug}_token.`;

    super($auth, options, ...(defaults));
  }
}
