
export default defineNuxtRouteMiddleware(async () => {
  const $auth = useAuth();

  if ($auth.strategy.name === 'magicLink') {
    const token = $auth.strategy.token.get();

    $auth.$storage.removeCookie('strategy');
    $auth.setStrategy('local');

    if (token) {
      $auth.strategy.token.set(token);
      await $auth.fetchUserOnce();
    }
  }
});
