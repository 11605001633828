import ROUTES from '~/assets/javascript/constants/routes';

export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuth();

  if (!auth.loggedIn) return null;

  const { $store } = useNuxtApp();
  const { name, query, params: { tenantSlug }, params } = to;
  const localePath = useLocalePath();

  if (query.id) {
    return navigateTo(localePath(
      { name: ROUTES.studio.workflow.routeName, params: { tenantSlug, id: query.id } },
    ));
  }

  if (params.id) {
    $store.commit('workflows/setStepsVisibility', false);
    $store.dispatch('workspace/setCategoryByWorkflowId', params.id);

    $store.dispatch('workflows/loadCurrentWorkflow', params.id)
      .catch((error) => {
        if (error?.response?.status !== 404) throw error;

        const firstWorkflowId = $store.getters['workspace/firstWorkflowId'];

        if (firstWorkflowId) {
          navigateTo(localePath({
            name: ROUTES.studio.workflow.routeName,
            params: { tenantSlug, id: firstWorkflowId },
          }));
        } else if (name.split('___')[0] === ROUTES.studio.workflow.routeName) {
          navigateTo(localePath({
            name: ROUTES.studio.workflows.routeName,
            params: { tenantSlug },
          }));
        }
      });

    return undefined;
  }

  const firstWorkflowId = $store.getters['workspace/firstWorkflowId'];

  if (firstWorkflowId) {
    return navigateTo(localePath({
      name: ROUTES.studio.workflow.routeName,
      params: { tenantSlug, id: firstWorkflowId },
    }));
  }

  if (name.split('___')[0] === ROUTES.studio.workflow.routeName) {
    return navigateTo(localePath({
      name: ROUTES.studio.workflows.routeName,
      params: { tenantSlug },
    }));
  }

  $store.dispatch('workflows/loadWorkflows');

  return undefined;
});
