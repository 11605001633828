import { default as indexOkzSYIEJRaMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/index.vue?macro=true";
import { default as indexH3UMR5uDXBMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/login/index.vue?macro=true";
import { default as index9nMiuORVxXMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetk3qveiFdeCMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexdlHsYuJn8uMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspacejNiPWqG2toMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as index1r1xgWtTHUMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93ZBK5SRMEy5Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexkxsUIWpEFHMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexZRMaJS5ktqMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexKVpRb2acoIMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexAYtpA4a5cFMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as indexToDg8yvDVHMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexWFJmN5JKi2Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexQpBpQWOctTMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newIQOCzA30AZMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationgYvljwuPz8Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexTJCywiCDgtMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mnb2ZycuTfbMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexMg0BaFveslMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as index4MP9BlsDAKMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexdaOeenKMZPMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordS3CNmZxeAvMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexKD1Wv0RNEOMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexNPulCZz8UXMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyzHZIAwkoORMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexqY3IXxnbYNMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexPhdLoXkf1SMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importQ92XFGjI6EMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexDlim5G4F3pMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemakJ1ioiFZYKMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexOT9fMBBb9bMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indexTosZT7MCr7Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_93vPTNMEhz9ZMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexFfD48c4Wj0Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexTkTnH3xxF7Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexTnxzg5LZGjMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexUnoKKIYIhzMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexDGRxxt6V2bMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93g446BPt4g9Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as indexPzZxef258VMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrations0Ix83GAKqoMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexLLt0KQW3tZMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexQo8dnLth7FMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as indexJ9bBR3PJV4Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_933Cg5QksejCMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexnW9fB9jxmuMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newEjXaXB0nO5Meta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupsFFPeVkqRsWMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as indexnBJPPSmHAhMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingsP9iWZLgp6cMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studioCalH6JSbBjMeta } from "/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexOkzSYIEJRaMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexOkzSYIEJRaMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93ZBK5SRMEy5Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93ZBK5SRMEy5Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexkxsUIWpEFHMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexkxsUIWpEFHMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexZRMaJS5ktqMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexZRMaJS5ktqMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexKVpRb2acoIMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexKVpRb2acoIMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexAYtpA4a5cFMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexAYtpA4a5cFMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexToDg8yvDVHMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexToDg8yvDVHMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mnb2ZycuTfbMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mnb2ZycuTfbMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexWFJmN5JKi2Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexQpBpQWOctTMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newIQOCzA30AZMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationgYvljwuPz8Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexTJCywiCDgtMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mnb2ZycuTfbMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mnb2ZycuTfbMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexWFJmN5JKi2Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexQpBpQWOctTMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newIQOCzA30AZMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationgYvljwuPz8Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexTJCywiCDgtMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexMg0BaFveslMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexMg0BaFveslMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4MP9BlsDAKMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4MP9BlsDAKMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexdaOeenKMZPMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexdaOeenKMZPMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordS3CNmZxeAvMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordS3CNmZxeAvMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexKD1Wv0RNEOMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexKD1Wv0RNEOMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studioCalH6JSbBjMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studioCalH6JSbBjMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyzHZIAwkoORMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexqY3IXxnbYNMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexPhdLoXkf1SMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importQ92XFGjI6EMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexDlim5G4F3pMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemakJ1ioiFZYKMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexOT9fMBBb9bMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_93vPTNMEhz9ZMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexFfD48c4Wj0Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsP9iWZLgp6cMeta?.name,
    path: "settings",
    meta: settingsP9iWZLgp6cMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrations0Ix83GAKqoMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsFFPeVkqRsWMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studioCalH6JSbBjMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studioCalH6JSbBjMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyzHZIAwkoORMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexqY3IXxnbYNMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexPhdLoXkf1SMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importQ92XFGjI6EMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexDlim5G4F3pMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemakJ1ioiFZYKMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexOT9fMBBb9bMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_93vPTNMEhz9ZMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexFfD48c4Wj0Meta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsP9iWZLgp6cMeta?.name,
    path: "settings",
    meta: settingsP9iWZLgp6cMeta || {},
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrations0Ix83GAKqoMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsFFPeVkqRsWMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src3770141398/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]